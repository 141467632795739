// StudentPage.js
import React from 'react';

const StudentsPage = () => {
    return (
        <div>
            <h2>10,000+ Students</h2>
            <p>Learn about our students and their success stories!</p>
            {/* Add detailed content and information here */}
        </div>
    );
};

export default StudentsPage;