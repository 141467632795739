import { useState } from 'react';
import { data, useNavigate } from 'react-router-dom';
import fullStackImg from '../assets/course-pics/full-stack.jpg';
import dataAnalysisImg from '../assets/course-pics/data-analysis.jpg';
import aiMlImg from '../assets/course-pics/ai-and-ml.jpg';
import dataScienceImg from '../assets/course-pics/data-science.jpg';
import frontendReact from '../assets/course-pics/reactjs.jpg';
import backendNode from '../assets/course-pics/nodejs.jpg';
import softwareEng from '../assets/course-pics/software-eng.jpg';


export const programs = {
        'Software Engineering': [
            {
                title: 'Full Stack Web Development',
                description: 'Learn to build modern web applications using React, Node.js, and other cutting-edge technologies.',
                img: fullStackImg,
                tags: ['Full-time Remote', 'Part-time Remote'],
                curriculumTopics: [
                    { title: 'HTML & CSS Fundamentals', details: 'Learn the basics of web structure and styling.' },
                    { title: 'JavaScript Essentials', details: 'Deep dive into JavaScript programming fundamentals.' },
                    { title: 'React Foundations', details: 'Building interactive user interfaces with React.' },
                    { title: 'Backend Development with Node.js', details: 'Server-side programming and API development.' },
                    { title: 'Database Integration', details: 'Working with MongoDB and database design.' },
                    { title: 'Full Stack Project', details: 'Comprehensive project integrating frontend and backend technologies.' }
                ],
            },
            {
                title: 'Software Engineering Bootcamp',
                description: 'Master software engineering principles and build scalable applications.',
                img: softwareEng,
                tags: ['Full-time Hybrid', 'Full-time Remote'],
                curriculumTopics: [
                    { title: 'Programming Fundamentals', details: 'Introduction to core programming concepts and best practices.' },
                    { title: 'Object-Oriented Programming', details: 'Advanced concepts in OOP design and implementation.' },
                    { title: 'Software Design Patterns', details: 'Learn industry-standard design patterns and architectural approaches.' },
                    { title: 'Version Control with Git', details: 'Collaborative coding and version management techniques.' },
                    { title: 'Agile Methodology', details: 'Understanding and implementing Agile software development practices.' },
                    { title: 'Capstone Project', details: 'Build a comprehensive software solution applying learned skills.' }
                ]
            },
        ],
        'Data Science': [
            {
                title: 'Data Analytics and Visualization',
                description: 'Learn to analyze and visualize data using Python and popular data science libraries.',
                img: dataAnalysisImg,
                tags: ['Full-time Remote', 'Part-time Remote'],
                curriculumTopics: [
                    { title: 'Python Programming', details: 'Introduction to Python programming for data analysis.' },
                    { title: 'Data Analysis with Pandas', details: 'Working with data frames and data manipulation in Python.' },
                    { title: 'Data Visualization with Matplotlib', details: 'Creating visualizations and plots using Matplotlib.' },
                    { title: 'Data Analysis Project', details: 'Develop a data analysis project showcasing learned skills.' }
                ]
            },
            {
                title: 'AI & Machine Learning',
                description: 'Dive deep into AI and Machine Learning with Python, TensorFlow, and Keras.',
                img: aiMlImg,
                tags: ['Full-time Remote', 'Part-time Remote'],
                curriculumTopics: [
                    { title: 'Python Programming', details: 'Fundamentals of Python programming for AI and ML.' },
                    { title: 'Machine Learning Basics', details: 'Introduction to ML concepts and algorithms.' },
                    { title: 'Deep Learning with TensorFlow', details: 'Building neural networks and deep learning models.' },
                    { title: 'Natural Language Processing', details: 'Text analysis and language processing with Python.' },
                    { title: 'AI Project', details: 'Develop an AI application showcasing learned skills.' }
                ]
            },
            {
                title: 'Data Science Bootcamp',
                description: 'An intensive course covering Data Analytics, Python, and Machine Learning.',
                img: dataScienceImg,
                tags: ['Full-time Remote', 'Part-time Remote'],
                curriculumTopics: [
                    { title: 'Python Essentials', details: 'Fundamentals of Python programming for Data Science. Python Loops & Functions. No prior coding experience is required.' },
                    { title: 'Introduction to Statistics', details: 'Overview of statistical concepts used in data analysis. Basic probability, distributions, and hypothesis testing.' },
                    { title: 'Data Visualization Fundamentals', details: 'Introduction to data visualization techniques and tools. Hands-on exercises with libraries like Matplotlib and Seaborn.' },
                    { title: 'Data Analysis Fundamentals', details: 'Using Python for data analysis. Data manipulation with Pandas, understanding datasets, and data cleaning.' },
                    { title: 'Project Period I', details: 'Hands-on project work involving data analysis and visualization tasks. Application of learned skills in real-world datasets.' },
                    { title: 'Data Cleaning with Pandas', details: 'Cleaning datasets using Pandas, handling missing data, and ensuring data quality.' },
                    { title: 'Data APIs', details: 'Introduction to using APIs for data access. Fetching and manipulating data from web sources using Python.' },
                    { title: 'Project Period II', details: 'Continuation of project work with advanced analysis tasks. Integration of data APIs and visualizations.' }
                ]
            },
        ],
        'Web Development': [
            {
                title: 'Frontend Development with React',
                description: 'Dive deep into React and build responsive, modern web interfaces.',
                img: frontendReact,
                tags: ['Full-time Remote', 'Part-time Remote'],
                curriculumTopics: [
                    { title: 'HTML5 & CSS3', details: 'Advanced web page structure and styling techniques.' },
                    { title: 'JavaScript ES6+', details: 'Modern JavaScript features and best practices.' },
                    { title: 'React Fundamentals', details: 'Building components, state management, and React hooks.' },
                    { title: 'State Management', details: 'Advanced state management with Redux and Context API.' },
                    { title: 'Responsive Design', details: 'Creating mobile-friendly and adaptive web applications.' },
                    { title: 'Frontend Project', details: 'Develop a comprehensive web application showcasing learned skills.' }
                ]
            },
            {
                title: 'Backend Development with Node.js',
                description: 'Learn to build robust backend systems with Node.js, Express, and MongoDB.',
                img: backendNode,
                tags: ['Full-time Remote', 'Part-time Remote'],
                curriculumTopics: [
                    { title: 'JavaScript for Backend', details: 'Server-side JavaScript and Node.js fundamentals.' },
                    { title: 'Express.js Framework', details: 'Building RESTful APIs and handling HTTP requests.' },
                    { title: 'Database Integration', details: 'Working with MongoDB and Mongoose ORM.' },
                    { title: 'Authentication & Security', details: 'Implementing user authentication and securing web applications.' },
                    { title: 'API Design', details: 'Best practices for designing scalable and maintainable APIs.' },
                    { title: 'Backend Project', details: 'Create a full-featured backend system demonstrating advanced skills.' }
                ]
            },
        ],
};


const Programs = () => {
    const [selectedDiscipline, setSelectedDiscipline] = useState('Software Engineering');
    const navigate = useNavigate();
    const [selectedProgram, setSelectedProgram] = useState(null);


    const handleLearnMore = (program) => {
        setSelectedProgram(program);
        // Navigate to the detailed program page (e.g., /programs/{programTitle})
        navigate(`/programs/${encodeURIComponent(program)}`);
    };

    return (
        <section className="bg-white dark:bg-gray-900 pt-20 md:pt-24">
            <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
                <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                        Explore Our Programs
                    </h2>
                    <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
                        Our courses are designed to help you learn the skills you need to land a job in the tech industry. We work with industry experts to create curriculum that is relevant and up-to-date.
                    </p>
                </div>

                {/* Tab Navigation */}
                <div className="flex justify-center mb-8 gap-4">
                    {Object.keys(programs).map((discipline) => (
                        <button
                            key={discipline}
                            className={`px-4 py-2 text-sm font-medium rounded-full shadow-md transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                                selectedDiscipline === discipline
                                    ? 'text-white bg-purple-600 hover:bg-purple-700'
                                    : 'text-purple-600 bg-gray-200 hover:bg-gray-300'
                            }`}
                            onClick={() => setSelectedDiscipline(discipline)}
                        >
                            {discipline}
                        </button>
                    ))}
                </div>

                {/* Program Cards */}
                <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                    {programs[selectedDiscipline].map((program, index) => (
                        <div
                            key={index}
                            className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                        >
                            <img
                                src={program.img}
                                alt={program.title}
                                className="mb-4 rounded-lg"
                            />
                            <h3 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white">
                                {program.title}
                            </h3>
                            <p className="mb-4 text-gray-600 dark:text-gray-400">
                                {program.description}
                            </p>
                            {program.tags.map((tag, idx) => (
                                <span
                                    key={idx}
                                    className="inline-block px-3 py-1 mb-4 mr-2 text-sm font-medium text-white bg-purple-600 rounded-full"
                                >
                                    {tag}
                                </span>
                            ))}
                            <button
                                onClick={() => handleLearnMore(program.title)}
                                className="block px-5 py-3 text-center text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 dark:focus:ring-purple-900"
                            >
                                Learn More
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Programs;
