import React, { useState } from 'react';

const CourseDetailsFAQ = ({ course }) => {
  const [activeCard, setActiveCard] = useState(null);

  // Comprehensive FAQ generation with more detailed content
  const generateFAQItems = () => {
    // Base FAQs that apply to most courses
    const baseFAQs = [
      {
        title: "Course Overview",
        content: course.description,
        icon: "📚"
      },
      {
        title: "Delivery Formats",
        content: `Flexible learning options including: ${course.tags.join(', ')}. Choose the format that best fits your schedule and learning style.`,
        icon: "🕒"
      }
    ];

    // Specialized FAQ content based on course categories
    const specializedFAQMap = {
      // Software Engineering Programs
      "Full Stack Web Development": [
        {
          title: "Technical Skills Learned",
          content: "Master full-stack development with React, Node.js, Express, and MongoDB. Learn front-end and back-end technologies, API design, and modern web development practices.",
          icon: "💻"
        },
        {
          title: "Career Opportunities",
          content: "Graduates are prepared for roles such as Full Stack Developer, Web Application Engineer, and Software Developer in tech startups and established companies.",
          icon: "🚀"
        }
      ],
      "Software Engineering Bootcamp": [
        {
          title: "Program Depth",
          content: "A comprehensive program covering software engineering principles, object-oriented programming, system design, and practical coding techniques used in industry.",
          icon: "🛠️"
        },
        {
          title: "Industry Preparation",
          content: "Build a professional portfolio, learn collaborative coding practices, and prepare for real-world software development challenges.",
          icon: "🌐"
        }
      ],
      
      // Data Science Programs
      "Data Analytics with Excel and Power BI": [
        {
          title: "Business Intelligence Focus",
          content: "Learn to transform raw data into actionable insights using Excel's advanced features and Power BI's visualization capabilities.",
          icon: "📊"
        },
        {
          title: "Practical Applications",
          content: "Master dashboard creation, data cleaning, statistical analysis, and reporting techniques used in business and finance.",
          icon: "💡"
        }
      ],
      "Data Visualization with Python": [
        {
          title: "Visualization Techniques",
          content: "Deep dive into Python libraries like Matplotlib, Seaborn, and Plotly. Learn to create compelling, interactive data visualizations.",
          icon: "🎨"
        },
        {
          title: "Real-World Projects",
          content: "Work on projects that demonstrate how to tell stories with data, from business reports to scientific research visualizations.",
          icon: "📈"
        }
      ],
      "Data Science Bootcamp": [
        {
          title: "Comprehensive Curriculum",
          content: "An intensive program covering data analytics, Python programming, machine learning, statistical analysis, and practical data science applications.",
          icon: "🧠"
        },
        {
          title: "Industry-Relevant Skills",
          content: "Prepare for roles like Data Scientist, Data Analyst, Machine Learning Engineer with hands-on, project-based learning.",
          icon: "🔬"
        }
      ],
      
      // Web Development Programs
      "Frontend Development with React": [
        {
          title: "Modern Web Technologies",
          content: "Master React ecosystem, including hooks, context, state management, and building responsive, interactive web applications.",
          icon: "⚛️"
        },
        {
          title: "UI/UX Principles",
          content: "Learn not just coding, but how to create user-friendly, visually appealing web interfaces that provide excellent user experiences.",
          icon: "🎯"
        }
      ],
      "Backend Development with Node.js": [
        {
          title: "Server-Side Mastery",
          content: "Learn to build scalable backend systems using Node.js, Express, MongoDB, with focus on API design, authentication, and server optimization.",
          icon: "🖧"
        },
        {
          title: "Cloud and Deployment",
          content: "Understand deployment strategies, cloud services, and how to take applications from local development to production environments.",
          icon: "☁️"
        }
      ]
    };

    // Combine base and specialized FAQs
    const specializedFAQs = specializedFAQMap[course.title] || [];
    return [...baseFAQs, ...specializedFAQs];
  };

  const faqItems = generateFAQItems();

  const toggleCard = (index) => {
    setActiveCard(activeCard === index ? null : index);
  };

  return (
    <div className="mt-12 bg-gradient-to-br from-gray-50 to-gray-100 p-6 rounded-2xl max-w-4xl mx-auto shadow-lg">
      <div className="text-center mb-8">
        <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600 mb-4">
          {course.title}
        </h2>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Dive deep into a transformative learning experience designed to accelerate your tech career.
        </p>
      </div>

      <div className="space-y-4">
        {faqItems.map((item, index) => (
          <div 
            key={index} 
            className={`
              border-2 rounded-xl transition-all duration-300 ease-in-out overflow-hidden
              ${activeCard === index 
                ? 'border-purple-500 shadow-xl' 
                : 'border-gray-200 hover:border-purple-300'}
            `}
          >
            <div 
              onClick={() => toggleCard(index)}
              className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-50"
            >
              <div className="flex items-center space-x-4">
                <span className="text-2xl">{item.icon}</span>
                <span className={`
                  text-lg font-semibold transition-colors duration-300
                  ${activeCard === index ? 'text-purple-700' : 'text-gray-800'}
                `}>
                  {item.title}
                </span>
              </div>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
                className={`
                  transition-transform duration-300
                  ${activeCard === index 
                    ? 'rotate-180 text-purple-600' 
                    : 'rotate-0 text-gray-500'}
                `}
              >
                {activeCard === index 
                  ? <path d="M18 15l-6-6-6 6" /> 
                  : <path d="M6 9l6 6 6-6" />}
              </svg>
            </div>
            {activeCard === index && (
              <div 
                className="px-4 pb-4 text-gray-700 bg-white animate-fade-in"
              >
                {item.content}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseDetailsFAQ;