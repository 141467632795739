// CountriesPage.js
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const CountriesPage = () => {
    const countries = [
        // Array of country objects with coordinates
        { name: 'United States', lat: 37.7749, lng: -122.4194 },
        { name: 'India', lat: 20.5937, lng: 78.9629 },
        // ... other countries
    ];

    return (
        <div>
            <h2>50+ Countries</h2>
            <p>View the global reach of our alumni!</p>
            <MapContainer center={[20, 0]} zoom={2} style={{ height: '400px', width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {countries.map((country, index) => (
                    <Marker key={index} position={[country.lat, country.lng]}>
                        <Popup>{country.name}</Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
};

export default CountriesPage;
