import React, { useState } from 'react';

const Curriculum = ({ topics }) => {
    const [openTopicIndex, setOpenTopicIndex] = useState(null);

    const toggleTopic = (index) => {
        setOpenTopicIndex(openTopicIndex === index ? null : index);
    };

    const ChevronDownSVG = () => (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            className="text-gray-500"
        >
            <path d="m6 9 6 6 6-6"/>
        </svg>
    );

    const ChevronUpSVG = () => (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            className="text-purple-600"
        >
            <path d="m18 15-6-6-6 6"/>
        </svg>
    );

    if (!topics || topics.length === 0) {
        return (
            <div className="mt-12 bg-gray-100 p-6 rounded-lg text-center text-gray-600">
                Curriculum details are not available for this program.
            </div>
        );
    }

    return (
        <div className="mt-12 bg-gray-100 p-6 rounded-lg">
            <h2 className="text-3xl font-bold mb-6 text-gray-900">Curriculum Overview</h2>
            <div className="space-y-4">
                {topics.map((topic, index) => (
                    <div 
                        key={index} 
                        className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden"
                    >
                        <button
                            onClick={() => toggleTopic(index)}
                            className="w-full flex justify-between items-center p-4 text-left 
                                hover:bg-gray-50 transition-colors duration-200 
                                focus:outline-none focus:ring-2 focus:ring-purple-500"
                            aria-expanded={openTopicIndex === index}
                            aria-controls={`topic-details-${index}`}
                        >
                            <span className="text-lg font-semibold text-gray-900">
                                {topic.title}
                            </span>
                            {openTopicIndex === index ? (
                                <ChevronUpSVG />
                            ) : (
                                <ChevronDownSVG />
                            )}
                        </button>
                        {openTopicIndex === index && (
                            <div 
                                id={`topic-details-${index}`}
                                className="p-4 pt-0 text-gray-600 animate-fade-in"
                            >
                                {topic.details}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Curriculum;