import React, { useState } from 'react';

const FAQs = () => {
    // State to track which FAQ is currently open
    const [openFAQ, setOpenFAQ] = useState(null);

    // Toggle FAQ expansion
    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };

    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-24 lg:px-6 ">
                <h2 className="mb-6 text-3xl font-extrabold tracking-tight text-center text-gray-900 lg:mb-8 lg:text-3xl dark:text-white">
                    Frequently asked questions
                </h2>
                <div className="max-w-screen-md mx-auto">
                    <div id="accordion-flush">
                        {[
                            {
                                question: "What makes Swiss Tech Academy different from other coding bootcamps?",
                                answer: "At Swiss Tech Academy, we focus on hands-on learning, real-world projects, and industry-relevant skills. Our curriculum is designed by experienced instructors and professionals to provide an in-depth understanding of programming languages, software development practices, and best coding techniques. We offer personalized support, small className sizes, and a community-driven environment to ensure every student receives the guidance they need to succeed."
                            },
                            {
                                question: "Who is Swiss Tech Academy suitable for?",
                                answer: "Swiss Tech Academy is designed for individuals of all levels, from beginners who are just starting their coding journey to experienced developers looking to level up their skills. Whether you want to switch careers, enhance your current knowledge, or simply build your coding confidence, Swiss Tech Academy offers a curriculum tailored to your needs. Our bootcamp is ideal for anyone who is committed to learning and ready to dedicate time and effort to mastering programming."
                            },
                            {
                                question: "What kind of support can I expect as a Swiss Tech Academy student?",
                                answer: "As a Swiss Tech Academy student, you'll receive ongoing support from our experienced instructors and a dedicated student success team. We offer personalized mentoring, one-on-one coding sessions, and help with career coaching and resume building. Additionally, our community forums allow you to connect with peers, share knowledge, and get advice from industry professionals. We are committed to supporting your journey from learning to landing your first job in tech."
                            },
                            {
                                question: "How hands-on is the Swiss Tech Academy experience?",
                                answer: "At Swiss Tech Academy, we emphasize practical learning. You will work on real-world projects, build portfolio-worthy applications, and participate in coding challenges that mirror what you might encounter in your future job. Our curriculum includes interactive exercises, pair programming sessions, and coding labs to help you apply what you've learned immediately. This hands-on approach ensures you gain the experience necessary to excel in your career."
                            },
                            {
                                question: "What job placement support does Swiss Tech Academy offer?",
                                answer: "We provide extensive job placement support through resume workshops, mock interviews, and networking opportunities with tech industry professionals. Our career coaches work closely with students to identify job openings, prepare for technical interviews, and connect with top companies looking for skilled developers. Swiss Tech Academy also offers job fairs, career panels, and access to a growing alumni network to help you launch your coding career confidently."
                            }
                        ].map((faq, index) => (
                            <div key={index}>
                                <h3 id={`accordion-flush-heading-${index + 1}`}>
                                    <button 
                                        type="button" 
                                        className={`flex items-center justify-between w-full py-5 font-medium text-left border-b border-gray-200 dark:border-gray-700 ${
                                            openFAQ === index 
                                                ? 'text-gray-900 dark:text-white bg-white dark:bg-gray-900' 
                                                : 'text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-900'
                                        }`}
                                        onClick={() => toggleFAQ(index)}
                                    >
                                        <span>{faq.question}</span>
                                        <svg 
                                            className={`w-6 h-6 shrink-0 ${
                                                openFAQ === index ? 'rotate-180' : ''
                                            }`} 
                                            fill="currentColor" 
                                            viewBox="0 0 20 20" 
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path 
                                                fillRule="evenodd" 
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" 
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </button>
                                </h3>
                                <div 
                                    id={`accordion-flush-body-${index + 1}`} 
                                    className={`${openFAQ === index ? '' : 'hidden'}`}
                                >
                                    <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                                            {faq.answer}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQs;